import {BaseService} from "@/core/services/BaseService";

class ReportBuilderService extends BaseService<any> {
  endpoint = "/report-builder";

  async listFilterFields(type: any) {
    return this.http().get(this.endpoint + "/filter-fields/" + type).then(res => {
      return res.data
    });
  }


  async run(id, data) {
    console.log(data);
    return this.http().post(this.endpoint + "/" + id + "/run", data).then(res => {
      return res.data
    })
  }

  async export(id, data) {
    return this.http().post(this.endpoint + "/" + id + "/export", data, {responseType: "blob"}).then(res => {
      return res
    })
  }

  async preview(data) {
    return this.http().post(this.endpoint + "/preview", data).then(res => {
      return res.data
    })
  }

  async previewExport(data) {
    return this.http().post(this.endpoint + "/export", data, {responseType: "blob"}).then(res => {
      return res.data
    })
  }
}

export default new ReportBuilderService();