import { computed } from "vue";
import type { IUserPermission } from "@/core/entity/IUserPermission";


export default {
  mounted(el, binding, vnode) {
    const func = binding.value.function;
    const action = binding.value.action;
    const vm = binding.instance
    const permissions = computed<IUserPermission[]>(() => vm.$store?.state.PermissionModule.permissions)
    if (permissions.value === undefined || permissions.value === null) {
      el.classList.add("d-none")
    }
    vm.$store.watch(() => vm.$store.state.PermissionModule.permissions, (pers: IUserPermission[], a) => {
      const permission = pers.filter(item => item.function == func && item.action == action)[0]
      if (!permission) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      } else {
        el.classList.remove("d-none")
      }
    })
    if (permissions.value.length > 0) {
      const permission = permissions.value.filter(item => item.function == func && item.action == action)[0]
      if (!permission) {
        el.parentNode.removeChild(el);
      }
    }
  }

}