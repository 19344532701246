
import {computed, defineComponent, ref} from 'vue'
import store from "@/store";
import {LoadEntity} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ReportCriteria from "@/views/report-builder/ReportCriteria.vue";
import ReportBuilderData from "@/views/report-builder/ReportBuilderData.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReportBuilderForm from "@/views/report-builder/ReportBuilderForm.vue";

export default defineComponent({
  name: "ReportBuilderPage",
  components: {ReportBuilderForm, BaseModal, QuickAction, ReportBuilderData, ReportCriteria, EntityLoading},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Report Builder',
      [
        {link: true, router: '/report-builder', text: 'List'},
        {link: false, router: '', text: 'Report Details'},
      ]
    )
    const entity = computed(() => store.state.ReportBuilderModule.report);
    const report = ref({name: '', type: '', criteria: [], columns: []})
    return {
      entity,
      report,
      ...LoadEntity(Actions.LOAD_REPORT_BUILDER_ENTITY),
    }
  },
  methods: {
    onEdit() {
      const modal = this.$refs.editReportBuilderRef as typeof BaseModal;
      modal.showBaseModal()
      this.report = {
        name: this.entity.report.name,
        type: this.entity?.report.config?.objectType,
        criteria: this.entity?.report?.config?.criteria,
        columns: this.entity?.report?.config?.columns
      }
    },
    onSave() {
      const modal = this.$refs.editReportBuilderRef as typeof BaseModal;
      modal.hideBaseModal()
      this.reloadData();
    }
  }
})
