import {computed} from "vue";

export default {
  mounted(el, binding) {
    const access = binding.value;
    const currentUser = computed<any>(() => vm.$store?.getters.currentUser)

    const vm = binding.instance
    if (currentUser.value === undefined || currentUser.value === null) {
      el.classList.add("d-none")
    }

    vm.$store.watch(() => vm.$store.state.AuthModule.user, (user: any) => {
      if (user && !user.access[access]) {
        if (el.parentNode) {
          el.parentNode.removeChild(el);
        }
      } else {
        el.classList.remove("d-none")
      }
    })
    if (currentUser.value?.id && !currentUser?.value?.access[access]) {
      el.parentNode.removeChild(el);
    }
  }
}