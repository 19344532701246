import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {ITableResponse} from "@/core/entity/ITableResponse";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ReportBuilderService from "@/core/services/ReportBuilderService";

@Module
export default class ReportBuilderModule extends VuexModule {
  page: ITableResponse<any> = {
    total: 0,
    data: [],
    pages: 0,
  }

  report: any = {loading: true, report: {}}

  reportData: any = {data: [], loading: true}


  @Action
  [Actions.LOAD_REPORT_BUILDER](filter: any) {
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    ReportBuilderService.findAll(filter).then(res => {
      this.context.commit(Mutations.SET_REPORT_BUILDER, res)
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }


  @Action
  [Actions.LOAD_REPORT_BUILDER_ENTITY](id) {
    this.context.commit(Mutations.SET_REPORT_BUILDER_ENTITY, {loading: true, report: {}})
    ReportBuilderService.get<any>(id).then(res => {
      this.context.commit(Mutations.SET_REPORT_BUILDER_ENTITY, {loading: false, report: res});
      this.context.dispatch(Actions.RUN_REPORT_BUILDER, {id: res.id, criteria: res.config.criteria})
    });
  }

  @Action
  [Actions.RUN_REPORT_BUILDER](data) {
    this.context.commit(Mutations.SET_RUN_REPORT_BUILDER, {loading: true, data: []})
    ReportBuilderService.run(data.id, data.criteria).then(res => {
      this.context.commit(Mutations.SET_RUN_REPORT_BUILDER, {loading: false, data: res});
    })
  }

  @Mutation
  [Mutations.SET_RUN_REPORT_BUILDER](data) {
    this.reportData = data;
  }

  @Mutation
  [Mutations.SET_REPORT_BUILDER](data) {
    this.page = data;
  }

  @Mutation
  [Mutations.SET_REPORT_BUILDER_ENTITY](data) {
    this.report = data
  }
}