import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = { class: "menu-item px-3" }
const _hoisted_6 = { class: "card-body" }
const _hoisted_7 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReportBuilderForm = _resolveComponent("ReportBuilderForm")!
  const _component_BaseModal = _resolveComponent("BaseModal")!
  const _component_entity_loading = _resolveComponent("entity-loading")!
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_ReportCriteria = _resolveComponent("ReportCriteria")!
  const _component_ReportBuilderData = _resolveComponent("ReportBuilderData")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_BaseModal, {
      id: "editReportBuilder",
      name: "Edit Report",
      ref: "editReportBuilderRef"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_ReportBuilderForm, {
          report: _ctx.report,
          onSaved: _ctx.onSave,
          id: _ctx.entity?.report?.id
        }, null, 8, ["report", "onSaved", "id"])
      ]),
      _: 1
    }, 512),
    (_ctx.entity.loading)
      ? (_openBlock(), _createBlock(_component_entity_loading, { key: 0 }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (_ctx.entity?.report?.id)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.entity.report.name), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_QuickAction, null, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_5, [
                          _createElementVNode("a", {
                            href: "javascript:void(0);",
                            class: "menu-link px-3",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onEdit && _ctx.onEdit(...args)))
                          }, " Edit ")
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_ReportCriteria, {
                    criteria: _ctx.entity.report.config.criteria,
                    id: _ctx.entity.report.id,
                    ref: "reportCriteriaRef"
                  }, null, 8, ["criteria", "id"])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_ReportBuilderData)
                ])
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 64))
}