import {createI18n} from "vue-i18n/index";

const messages = {
  en: {
    dashboard: "Dashboard",
    Dashboard: "Dashboard",
    home: "Home",
    newCases: "New Cases",
    newClaims: "New Claims",
    newInvoices: "New Invoices",
    newPayments: "New Payments",
    newActivities: "New Activities",
    filesUploaded: "Files Uploaded",
    tasks: "Tasks",
    overDue: "Overdue",
    dueToday: "Due Today",
    reminder: "Reminder",
    reminders: "Reminders",
    policy: "Policy",
    search: "Search",
    Search: "Search",
    recent: "Recent",
    watching: "Watching",
    cases: "Cases",
    myCases: "My Cases",
    Claims: "Claims",
    NewClaims: "New Claims",
    FastTrack: "Fast Track",
    MyClaims: "My Claims",
    Invoices: "Invoices",
    NewInvoices: "New Invoices",
    MyInvoices: "My Invoices",
    Providers: "Providers" ,
    CaseManagement: "Case Management",
    InpatientCases: "Inpatient Cases",
    Hospitalizations: "Hospitalizations",
    OutpatientCases: "Outpatient Cases",
    NoExaminer: "No Examiner",
    DocsOverdue: "Docs Overdue",
    RemindersDue: "Reminders Due",
    ManagerApprovals: "Manager Approvals",
    ClientApprovals: "Client Approvals",
    WaitingDecision: "Waiting Decision",
    CallCenter: "Call Center",
    PhoneCalls: "Phone Calls",
    Emails: "Emails",
    Payments: "Payments",
    List: "List",
    GroupPayments: "Group Payments",
    ChequeRegister: "Cheque Register",
    ClientFees: "Client Fees",
    ClientDashboards: "Client Dashboards",
    Reserves: "Reserves",
    CostContainment: "Cost Containment",
    Recovery: "Recovery",
    TridentDashboards: "Trident Dashboards",
    ReportsStorage: "Reports Storage",
    TimeReported: "Time Reported",
    SystemTimeLog: "System Time Log",
    Team: "Team",
    ActionLog: "Action Log",
    LoginAudit: "Login Audit",
    Tasks_Reminders: "Tasks & Reminders",
    MyTasks: "My Tasks",
    "My Tasks": "My Tasks",
    MyTeamTasks: "My Team Tasks",
    MyReminders: "My Reminders",
    "My Reminders": "My Reminders",
    AllTasks: "All Tasks",
    AllReminders: "All Reminders",
    DataConsistency: "Data Consistency",
    "All Data Problems" : "All Data Problems",
    "Processes" : "Processes",
    "Medical Reviews" : "Medical Reviews",
    "Complaints" : "Complaints",
    "Inquiries" : "Inquiries",
    "Appeals" : "Appeals",
    "Issues" : "Issues",
    "Escalation" : "Escalation",
    "Sanction Checks" : "Sanction Checks",
    "Customer" : "Customer",
    "Inbox" : "Inbox",
    "New Customers" : "New Customers",
    "Confirmed Customers" : "Confirmed Customers",
    "Pending Claims" : "Pending Claims",
    "Tools" : "Tools",
    "Companies" : "Companies",
    "Users" : "Users",
    "Login Audit" : "Login Audit",
    "Teams" : "Teams",
    "Templates" : "Templates",
    "Notifications" : "Notifications",
    "Lookups" : "Lookups",
    "Tags" : "Tags",
    "My Claims Waiting Decision" : "My Claims Waiting Decision",
    "My Claim Documents Reminders Due" : "My Claim Documents Reminders Due",
    "Claims Waiting My Approval" : "Claims Waiting My Approval",
    "My Data Problems" : "My Data Problems",
    "Medical Reviews Assigned to Me" : "Medical Reviews Assigned to Me",
    "Complaints Assigned to Me" : "Complaints Assigned to Me",
    "Inquiries Assigned to Me" : "Inquiries Assigned to Me",
    "Appeals Assigned to Me" : "Appeals Assigned to Me",
    "Issues Assigned to Me" : "Issues Assigned to Me",
    "Escalations Assigned to Me" : "Escalations Assigned to Me",
    "ReportBuilder" : "ReportBuilder",
  },
  fr: {
    dashboard: "Générateur de mise",
    Dashboard: "Générateur de mise",
    home: "Maison",
    newCases: "Nouveaux cas",
    newClaims: "Nouvelles revendications",
    newInvoices: "Nouvelles factures",
    newPayments: "Nouveaux paiements",
    newActivities: "Nouvelles activités",
    filesUploaded: "Fichiers téléchargés",
    tasks: "Tâches",
    overDue: "En retard",
    dueToday: "À rendre aujourd'hui",
    reminder: "Rappel",
    reminders: "Rappels",
    policy: "Politique",
    search: "Recherche",
    Search: "Recherche",
    recent: "Récent",
    watching: "Regarder",
    cases: "Cas",
    myCases: "Mes cas",
    Claims: "Réclamations",
    NewClaims:"Nouvelles revendications",
    FastTrack: "Voie rapide",
    MyClaims: "Mes réclamations",
    Invoices: "Factures",
    NewInvoices: "Nouvelles factures",
    MyInvoices: "Mes factures",
    Providers: "Fournisseurs" ,
    CaseManagement: "Gestion des cas" ,
    InpatientCases: "Cas d'hospitalisation",
    Hospitalizations: "Hospitalisations",
    OutpatientCases: "Cas ambulatoires",
    NoExaminer: "Pas d'examinateur",
    DocsOverdue: "Documents en retard",
    RemindersDue: "Rappels dus",
    ManagerApprovals: "Approbations du gestionnaire",
    ClientApprovals: "Approbations des clients",
    WaitingDecision: "En attente de décision",
    CallCenter: "Centre d'appel",
    PhoneCalls: "Appels téléphoniques",
    Emails: "Courriels",
    Payments: "Paiements",
    List: "Liste",
    GroupPayments: "Paiements de groupe",
    ChequeRegister: "Registre des chèques",
    ClientFees: "Honoraires clients",
    Reports: "Rapports",
    ClientDashboards: "Tableaux de bord clients",
    Reserves: "Réserves",
    CostContainment: "Maîtrise des coûts",
    Recovery: "Récupération",
    ReportsStorage: "Stockage des rapports",
    TridentDashboards: "Tableaux de bord Trident",
    TimeReported: "Heure rapportée",
    SystemTimeLog: "Journal de l'heure du système",
    Team: "Équipe",
    ActionLog: "Journal des actions",
    LoginAudit: "Audit de connexion",
    Tasks_Reminders: "Tâches et rappels",
    MyTasks: "Mes tâches",
    "My Tasks": "Mes tâches",
    MyTeamTasks: "Mes tâches d'équipe",
    AllTasks: "Toutes les tâches",
    "My Reminders": "Mes rappels",
    MyReminders: "Mes rappels",
    AllReminders: "Tous les rappels",
    DataConsistency: "Cohérence des données",
    "All Data Problems" : "Tous les problèmes de données",
    "Processes" : "Processus",
    "Medical Reviews" : "Avis médicaux",
    "Complaints" : "Plaintes",
    "Inquiries" : "Demandes de renseignements",
    "Appeals" : "Appels",
    "Issues" : "Problèmes",
    "Escalation" : "Escalade",
    "Sanction Checks" : "Contrôles de sanctions",
    "Customer" : "Cliente",
    "Inbox" : "Boîte de réception",
    "New Customers" : "Nouveaux clients",
    "Confirmed Customers" : "Clients confirmés",
    "Pending Claims" : "Réclamations en cours",
    "Tools" : "Outils",
    "Companies" : "Entreprises",
    "Users" : "Utilisatrices",
    "Login Audit" : "Audit de connexion",
    "Teams" : "Équipes",
    "Templates" : "Modèles",
    "Notifications" : "Notifications",
    "Lookups" : "Recherches",
    "Tags" : "Mots clés",
    "My Claims Waiting Decision" : "Mes réclamations en attente de décision",
    "My Claim Documents Reminders Due" : "Rappels de mes documents de réclamation",
    "Claims Waiting My Approval" : "Réclamations en attente de mon approbation",
    "My Data Problems" : "Mes problèmes de données",
    "Medical Reviews Assigned to Me" : "Examens médicaux qui me sont attribués",
    "Complaints Assigned to Me" : "Plaintes qui m'ont été attribuées",
    "Inquiries Assigned to Me" : "Enquêtes qui me sont confiées",
    "Appeals Assigned to Me" : "Appels qui m'ont été confiés",
    "Issues Assigned to Me" : "Problèmes qui m'ont été assignés",
    "Escalations Assigned to Me" : "Escalades qui me sont assignées",
    "ReportBuilder" : "Report Builder",
  },

};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
