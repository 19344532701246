
import {defineComponent} from 'vue'
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ReportBuilderPreview",
  components: {DateTimeFormat},
  props: {
    columns: {},
    data: {},
    loading: {}
  },
  setup() {
    return {...LoadPanel()}
  }

})
