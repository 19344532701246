import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import TaskService from "@/core/services/TaskService";
import store from "@/store";

@Module
export default class TaskModule extends VuexModule {
  allPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  pendingPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  completedPage: any = {total: 0, data: [], totalPages: 0, loading: true}
  selectedTask: any = {show: false, data: {}, loading: false}
  taskCompleteId: any

  @Action
  [Actions.LOAD_ALL_TASKS](filter) {
    // this.context.commit(Mutations.SET_ALL_TASKS, {total: 0, data: [], totalPages: 0, loading: true})
    this.context.commit(Mutations.SET_SEARCH_LOADING, true)
    TaskService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_ALL_TASKS, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    }).finally(() => {
      this.context.commit(Mutations.SET_SEARCH_LOADING, false)
    })
  }

  @Action
  [Actions.LOAD_PENDING_TASKS](filter) {
    this.context.commit(Mutations.SET_PENDING_TASKS, {total: 0, data: [], totalPages: 0, loading: true})
    TaskService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_PENDING_TASKS, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    })
  }

  @Action
  [Actions.LOAD_COMPLETED_TASK](filter) {
    this.context.commit(Mutations.SET_COMPLETED_TASK, {total: 0, data: [], totalPages: 0, loading: true})
    TaskService.findAll<any>(filter).then(res => {
      this.context.commit(Mutations.SET_COMPLETED_TASK, {
        total: res.total,
        data: res.data,
        totalPages: res.totalPage,
        loading: false
      })
    })
  }

  @Action
  [Actions.LOAD_TASK](id) {
    this.context.commit(Mutations.SET_REMINDER, {show: false, data: {}, loading: false})
    this.context.commit('SET_SHOW_TASK_DETAIL', true)
    this.context.commit('SET_LOADING_TASK_DETAIL', true)
    return TaskService.get(id).then(res => {
      this.context.commit('SET_TASK', res);
    }).finally(() => {
      this.context.commit('SET_LOADING_TASK_DETAIL', false)
    })
  }

  @Mutation
  [Mutations.SET_ALL_TASKS](data) {
    this.allPage = data;
  }

  @Mutation
  [Mutations.SET_PENDING_TASKS](data) {
    this.pendingPage = data;
  }

  @Mutation
  [Mutations.SET_COMPLETED_TASK](data) {
    this.completedPage = data;
  }

  @Mutation
  ['SET_LOADING_TASK_DETAIL'](loading) {
    this.selectedTask.loading = loading
  }

  @Mutation
  ['SET_SHOW_TASK_DETAIL'](show) {
    this.selectedTask.show = show
  }

  @Mutation
  ['SET_TASK'](data) {
    this.selectedTask.data = data
  }

  @Mutation
  ['TASK_COMPLETE'](id) {
    this.taskCompleteId = id;
  }

  @Mutation
  ['TASK_DELETE'](id) {
    this.allPage.data = this.allPage.data.filter(item => item.id !== id);
    this.pendingPage.data = this.allPage.data.filter(item => item.id !== id);
    this.completedPage.data = this.allPage.data.filter(item => item.id !== id);
  }
}