
import {computed, defineComponent, ref, watch} from 'vue'
import {Field} from "vee-validate";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2";
import {makeDownloadLink} from "@/core/helpers/functions";
import ReportBuilderService from "@/core/services/ReportBuilderService";

export default defineComponent({
  name: "ReportCriteria",
  components: {BaseSelect, Field},
  props: {
    id: {required: true},
    criteria: {type: Object, required: true},
  },
  setup(props) {
    const model = ref(props.criteria);
    watch(() => props.criteria, cb => {
      model.value = cb;
    })
    const reportData = computed(() => store.state.ReportBuilderModule.reportData)
    const loading = ref(false);
    return {
      reportData,
      model,
      loading,
    }
  },
  methods: {
    run() {
      store.dispatch(Actions.RUN_REPORT_BUILDER, {id: this.id, criteria: this.model})
    },
    exportData() {
      this.loading = true;
      Swal.fire({
        heightAuto: false,
        onOpen: () => {
          Swal.showLoading()
        },
      });
      ReportBuilderService.export(this.id, this.model).then(res => {
        makeDownloadLink(res);
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false;
        Swal.close();
      })

    }
  }
})
