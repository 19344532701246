
import {computed, defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ClaimCard from "../ClaimCard.vue";
import Cards from "../../../components/base/common/Cards.vue";
import store from "../../../store";
import {CompanySelect, ExaminerSelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "../../../components/base/common/Entities.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "ReviewDue",
  components: {InfoBox, BaseSelect, Entities, Cards, ClaimCard},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Claim Review Due',
        [
          {link: false, router: '', text: 'Claim Review Due'}
        ]
      )
    })
    const filterObject = ref({claimReviewDue: true, clientId: '', exId: ''})
    const page = computed(() => store.state.ClaimModule.listPageClaim)
    return {
      ...CompanySelect(),
      filterObject,
      page,
      ...LoadFilterObjects(Actions.LOAD_LIST_CLAIMS, filterObject.value, ['client', 'insured', 'caze', 'examiner']),
      ...ExaminerSelect(),
    }
  },
  methods: {
    onFilter() {
      this.updateFilterObject(this.filterObject);
    },
    onDiscard() {
      this.filterObject = {
        claimReviewDue: true,
        clientId: '',
        exId: '',
      }
      this.updateFilterObject(this.filterObject);
    }
  }
})
