
import {defineComponent, ref} from 'vue'
import PolicyService from "@/core/services/PolicyService";
import PolicyLink from "@/views/policy/PolicyLink.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "PolicyPhoneData",
  components: {Row, DateTimeFormat, PolicyLink},
  props: {
    id: {type: String, required: true},
  },
  setup(props) {
    const loading = ref(false);
    const policy = ref<any>({});
    const loadData = (id) => {
      loading.value = true;
      PolicyService.get(id).then(res => {
        policy.value = res;
      }).finally(() => {
        loading.value = false;
      })
    }
    loadData(props.id)
    return {
      policy,
      ...LoadPanel(),
      loading,
    }
  }
})
