
import {defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";
import {CompanySelect, ExaminerSelect} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import Chart from "@/views/chart/Chart.vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "InvoicesToReprice",
  components: {InfoBox, Chart, BaseSelect, DataTable, Entities},
  setup() {
    const clientId = ref('')
    const examinerId = ref('')
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Invoices Pending Repricing',
          [
            {link: false, router: '', text: 'Invoices Pending Repricing'}
          ]
      )
    })
    return {
      examinerId,
      ...ExaminerSelect(),
      ChartType,
      clientId,
      ...CompanySelect(),
    }
  }
})
