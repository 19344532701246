
import {computed, defineComponent} from 'vue'
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";
import {useI18n} from "vue-i18n";
import store from "@/store";

export default defineComponent({
  name: "DashboardWidgets",
  components: {DataTable},
  props: {
    userId: {}
  },
  setup() {
    const {t} = useI18n();
    const user = computed(()=>store.getters.currentUser);
    return {ChartType, t,user}
  }
})
