
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {CompanySelect, ExaminerSelect} from "@/core/composite/composite";
import Entities from "../../../components/base/common/Entities.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import InfoBox from "@/components/base/InfoBox.vue";
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";

export default defineComponent({
  name: "RemindersDue",
  components: {DataTable, InfoBox, BaseSelect, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Reminders Due',
          [
            {link: false, router: '', text: 'Reminders Due'}
          ]
      )
    })
    const filterObject = ref({ company_id: '', examiner_id: ''})
    return {
      ...CompanySelect(),
      ChartType,
      filterObject,
      ...ExaminerSelect(),
    }
  },
  methods: {
  }
})
