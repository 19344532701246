
import {defineComponent, onMounted, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ChartCode, ChartType} from "@/core/config/Constant";
import Chart from "@/views/chart/Chart.vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "TRClaims",
  components: {InfoBox, Chart},
  setup() {
    const clientId = ref('');
    const mounted = ref(false)
    onMounted(() => {
      mounted.value = true;
      setCurrentPageBreadcrumbsWithParams("Claims Dashboard", [])
    })

    return{
      mounted,
      clientId,
      ChartCode,
      ChartType,
    }
  }
})
