
import {Field, Form} from 'vee-validate'
import {computed, defineComponent, reactive, ref, watch} from "vue";
import * as Yup from "yup";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import {useStore} from "vuex";
import {ILocation} from "@/core/entity/ILocation";
import {IAddress, ModelAddress} from "@/core/entity/IAddress";
import {IPolicy} from "@/core/entity/IPolicy";
import {
  CompanySelect,
  CoordinatorSelect,
  ExaminerSelect,
  HandleState,
  InsuredSelect,
  PolicySelect
} from "@/core/composite/composite";
import CaseService from "@/core/services/CaseService";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import {CASE_TYPE, RESERVE_LINE_TYPE} from "@/core/config/Constant";
import Froala from "@/components/base/editor/Froala.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import FormCurrency from "@/components/base/currency/FormCurrency.vue";
import {exchangeRate} from "@/core/helpers/functions";

export interface ICaseState {
  type: string;
  clientId: string;
  insuredId: string;
  policyId: string;
  address: IAddress
  incidentDt: number;
  details: string;
  coordinatorId: string;
}

export default defineComponent({
  name: "FormCase",
  components: {
    FormCurrency,
    FormErrorMessage,
    Froala,
    // BaseRadio,
    DateTimeSelect, BaseSelect, Field, Form
  },
  props: {
    clientId: {type: String, default: ''},
    policyId: {type: String, default: ''},
    caze: {
      type: Object, default: () => {
      }
    }
  },
  setup(props) {
    const index = ref(1);
    const store = useStore();
    const submitting = ref(false);
    const submitButton = ref<HTMLButtonElement | null>(null);
    const formCase = Yup.object().shape({
      type: Yup.string().required().label("Type"),
      email: Yup.string().email().label("Type"),
      insuredId: Yup.string().required().label("Insured")
    });
    const caseState = reactive<any>({
      type: props.caze.type.code,
      policyId: props.policyId,
      clientId: props.clientId,
      insuredId: props.caze?.insured?.id,
      address: props.caze.address ? props.caze.address : new ModelAddress(),
      incidentDt: props.caze.incidentDt ? props.caze.incidentDt : new Date().getTime(),
      details: props.caze.details ? props.caze.details : '',
      coordinatorId: props.caze.coordinator ? props.caze.coordinator.id : '',
      managerId: props.caze.manager ? props.caze.manager.id : '',
      symptoms: props.caze.symptoms ? props.caze.symptoms : '',
      email: props.caze.email,
      examinerId: props.caze.examinerId,
      createClaim: props.caze.createClaim,
      reserveLine: props.caze.reserveLine,
      tripX: props.caze.tripX ? props.caze.tripX : {
        bookingDt: '',
        departurePlanDt: '',
        departureDt: '',
        returnPlanDt: '',
        returnDt: '',
        details: ''
      }
    })
    watch(() => props.caze, (cb) => {
      if (cb.id) {
        caseState.type = cb.type.code;
        caseState.insuredId = cb.insured.id;
        Object.assign(caseState.address, cb.address);
        caseState.incidentDt = cb.incidentDt;
        caseState.details = cb.details;
        caseState.coordinatorId = cb?.coordinator?.id
        caseState.managerId = cb?.manager?.id
        caseState.symptoms = cb.symptoms;
        caseState.email = cb.email;
      } else {
        caseState.type = '';
        caseState.insuredId = cb?.insured?.id;
        caseState.address = new ModelAddress();
        caseState.incidentDt = new Date().getTime();
        caseState.details = '';
        caseState.coordinatorId = cb.coordinator ? cb.coordinator.id : ''
        caseState.managerId = cb.manager ? cb.manager.id : ''
        caseState.email = '';
        caseState.reserve = cb.reserve;
      }
    })

    const countries = computed<ILocation[]>(() => store.state.LocationModule.countries)
    const states = computed<ILocation[]>(() => store.state.LocationModule.states)
    const policy = computed<IPolicy>(() => store.state.PolicyModule.policy)
    const rates = computed(() => store.state.ExchangeRateModule.rates);
    const calculateTotal = () => {

      const rate = rates.value.find(item => item.base === 'CAD' && item.currency === caseState.reserveLine.currency);
      if (rate) {
        caseState.reserveLine.valueCAD = exchangeRate(rate.rate, caseState.reserveLine.value, true).toFixed(2)
      } else {
        caseState.reserveLine.valueCAD = caseState.reserveLine.value
      }
    }
    return {
      submitButton,
      formCase,
      caseState,
      countries,
      states,
      policy,
      ...HandleState(),
      ...CompanySelect(),
      ...PolicySelect(),
      ...InsuredSelect(props.policyId),
      ...CoordinatorSelect(),
      ...ExaminerSelect(),
      CASE_TYPE,
      index,
      submitting,
      calculateTotal,
      RESERVE_LINE_TYPE,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true
      if (this.caze.id) {
        CaseService.update(this.caze.id, this.caseState).then(res => {
          this.$emit("onSaveCase", res)
        }).finally(() => {
          this.submitting = false
        })
      } else {
        CaseService.create(this.caseState).then(res => {
          this.$emit("onSaveCase", res)
        }).finally(() => {
          this.submitting = false
        })
      }
    },
    onNext() {

      this.index  =2;

    },
    onPrev() {
      this.index =1;
    },
    onTripXSubmit() {
      this.submitting = true
      CaseService.updateTripX(this.caze.id, this.caseState).then(res => {
        this.$emit("onSaveCase", res)
      }).finally(() => {
        this.submitting = false;
      })
    },
    onStateNameSelect(stateName) {
      const name = this.states.find((item) => item.code === stateName)?.name
      if (name) this.caseState.address.stateName = name
    },

    onCountryNameSelect(countryName) {
      const name = this.countries.find((item) => item.code === countryName)?.name
      if (name) this.caseState.address.countryName = name;
    },
    onCancel() {
      this.$router.go(-1)
    },

    onDeparturePlanSelect(time) {
      this.caseState.tripX.departureDt = time
    },
    onReturnPlanSelect(time) {
      this.caseState.tripX.returnDt = time
    }
  }
})
