
import {defineComponent, onMounted, ref} from 'vue'
import Entities from "@/components/base/common/Entities.vue";
import DataTable from "@/components/datatable/DataTable.vue";
import {ChartType} from "@/core/config/Constant";
import {CompanySelect, ExaminerSelect} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import InfoBox from "@/components/base/InfoBox.vue";

export default defineComponent({
  name: "ClaimWithDecision",
  components: {InfoBox, BaseSelect, DataTable, Entities},
  setup() {
    const clientId = ref('')
    const examinerId = ref('')
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Claims with Decision',
        [
          {link: false, router: '', text: 'Claims with Decision'}
        ]
      )
    })
    return {
      examinerId,
      ...ExaminerSelect(),
      ChartType,
      clientId,
      ...CompanySelect(),
    }
  }
})
