
import {computed, defineComponent} from 'vue'
import store from "@/store";
import {LoadPanel} from "@/core/composite/composite";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "ReportBuilderData",
  components: {DateTimeFormat},
  setup() {
    const data = computed(() => store.state.ReportBuilderModule.reportData)
    const report = computed(() => store.state.ReportBuilderModule.report);
    return {
      data,
      report,
      ...LoadPanel(),
    }
  }
})
