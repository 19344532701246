
import {computed, defineComponent, ref} from 'vue'
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ReportBuilderForm from "@/views/report-builder/ReportBuilderForm.vue";
import store from "@/store";
import {LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2";
import ReportBuilderService from "@/core/services/ReportBuilderService";

export default defineComponent({
  name: "List",
  components: {KtDatatable, ReportBuilderForm, BaseModal, QuickAction},
  setup() {
    setCurrentPageBreadcrumbsWithParams('Report Builder',
      [
        {link: false, router: '', text: 'List'},
      ]
    )
    const report = ref({name: '', type: '', criteria: [], columns: []});
    const filterObject = ref({})
    const page = computed(() => store.state.ReportBuilderModule.page);
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Owner",
        key: "owner",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: true,
      },
    ]);
    return {
      tableHeader,
      ...LoadFilterObjects(Actions.LOAD_REPORT_BUILDER, filterObject.value, ['owner']),
      page,
      filterObject,
      report,
    }
  },
  methods: {
    onAdd() {
      const modal = this.$refs.reportBuilderRef as typeof BaseModal;
      modal.showBaseModal();
      this.report = {name: '', type: '', criteria: [], columns: []};
    },
    onSaved() {
      this.paginationLoad()
      const modal = this.$refs.reportBuilderRef as typeof BaseModal;
      modal.hideBaseModal();
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ReportBuilderService.delete(id).then(() => {
            this.paginationLoad();
          })
        }
      })
    }
  }
})
