
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import ReportBuilderService from "@/core/services/ReportBuilderService";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import {Field} from "vee-validate";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import * as Yup from "yup";
import ReportBuilderPreview from "@/views/report-builder/ReportBuilderPreview.vue";

export default defineComponent({
  name: "ReportBuilderForm",
  components: {ReportBuilderPreview, BaseSelect, EntityLoading, BaseRadio, Field, FormErrorMessage, BaseForm},
  props: {
    id: {},
    report: {type: Object, required: true},
  },
  emits: ['saved'],
  setup(props) {
    const data = ref<any>([])
    const preview = ref(false);
    const loadingData = ref(false);
    const validate = Yup.object().shape({
      name: Yup.string().required().max(255).label('Name')
    })
    const filterFields = ref<any>({fields: [], columns: []});
    const submitting = ref<any>(false)
    const loading = ref(true)
    const model = ref<any>(props.report)

    watch(() => props.report, cb => {
      model.value = cb;
      preview.value = false;
      listFilterFields(cb.type)
    })

    watch(() => props.report.type, cb => {
      listFilterFields(cb)
    })
    const listFilterFields = (type) => {
      console.log(type)
      if (type) {
        ReportBuilderService.listFilterFields(type).then(res => {
          filterFields.value = res;
        }).finally(() => {
          loading.value = false
        })
      }
    }
    listFilterFields(props?.report?.type)

    return {
      model,
      validate,
      submitting,
      loadingData,
      filterFields,
      data,
      preview,
      loading,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      if (this.id) {
        ReportBuilderService.update(this.id, this.model).then(res => {
          this.$emit('saved', res)
        }).finally(() => {
          this.submitting = false;
        })
      } else
        ReportBuilderService.create(this.model).then(res => {
          this.$emit('saved', res)
          this.preview = false;
        }).finally(() => {

          this.submitting = false;
        })
    },

    onAddCriteria() {
      this.model.criteria.push({
        name: '',
        operators: [],
        operator: '',
        type: 'NA',
        value: '',
        label: ''
      })
    },
    onTypeChange(type: any) {
      this.preview = false;
      this.loading = true;
      this.model.criteria = []
      ReportBuilderService.listFilterFields(type).then(res => {
        this.filterFields = res;
        this.model.criteria.push({
          name: '',
          operators: [],
          operator: '',
          options: '',
          type: 'NA',
          value: '',
          label: ''
        })
        this.model.columns = res.columns;
      }).finally(() => {
        this.loading = false
      })
    },
    onFieldSelected(name) {
      const filed = this.filterFields.fields.find(item => item.name == name);
      if (filed) {
        const md = this.model.criteria.find(item => item.name === name)
        md.name = filed.name;
        md.type = filed.type;
        md.label = filed.label;
        md.operators = filed.operators;
        md.operator = filed.operator;
        md.options = filed.options;
        md.value = ''
      }
    },
    onRemoveLine(line) {
      this.model.criteria = this.model.criteria.filter(item => item !== line)
    },

    onChangeColum($event) {
      const name = $event.target.value;
      if ($event.target.checked) {
        const col = this.filterFields.columns.find(item => item.name == name);
        this.model.columns.push(col);
      } else {
        this.model.columns = this.model.columns.filter(item => item.name !== name)
      }
    },
    onPreview() {
      this.loadingData = true;
      this.preview = true
      this.submitting = true
      ReportBuilderService.preview(this.model).then(res => {
        this.data = res;
      }).finally(() => {
        this.loadingData = false;
        this.submitting = false;
      })
    }
  }
})
