
import {defineComponent, onMounted, reactive} from "vue";
import {useStore} from "vuex";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import FormCase from "@/views/case/FormCase.vue";
import {useRouter} from "vue-router";

export default defineComponent({
  name: "Create",
  components: {FormCase},
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('New Case', [
        {link: true, router: '/cases', text: 'Search Case'},
        {link: false, router: '', text: 'New Case'}
      ])
    })
    const model = reactive({
      caze: {
        type: {code: 'IP'},
        reserve: {type: 'HOSPITAL', value: 0, currency: 'CAD', valueCAD: 0}
      }
    })
    const createdCase = (caze: any) => {
      router.push({
        name: "case",
        params: {id: caze.id}
      });
    }
    return {
      model,
      createdCase,
      store,
    }
  }
})
